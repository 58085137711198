import React, { useContext, useEffect } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "../components/Auth/AuthContext";

type PrivateRouteProps = RouteProps & {
  fallback?: string;
};

export default function PrivateRoute(props: PrivateRouteProps) {
  const { fallback = "/login", ...routeProps } = props;
  const authContext = useContext(AuthContext);
  const isAuth = authContext.login && authContext.login.isLogged;
  const { setLogin } = useContext(AuthContext);

  const token = localStorage.getItem("token" || "");
  const userName = localStorage.getItem("username" || "");

  const isToken = (token: any) => {
    token !== null && token.length > 30
      ? setLogin({
          isLogged: true,
          userName: userName
        })
      : setLogin({
          isLogged: false,
          userName: "",
          token: ""
        });
  };

  useEffect(() => {
    isToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{isAuth ? <Route {...routeProps} /> : <Redirect to={fallback} />}</>;
}
