import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { FetchDBLog } from "../models/monitoring-models";
import { ServiceLog } from "../models/monitoring-models";
import { dateIsoToLocale } from "../services/monitoring-service";
import {
  DB_LOG_URL,
  LAST_24H_DB_LOG_URL,
  LAST_MONTH_DB_LOG_URL
} from "../routes";
import ErrorIcon from "@material-ui/icons/Error";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import CircularDeterminate from "../components/Monitoring/CircularDeterminate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: "20px",
      marginTop: "50px"
    },
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    menuItem: {
      height: "175px",
      display: "flex"
    },
    item: {
      display: "flex",
      color: "black",
      "&$selected": {
        backgroundColor: "#66FF99"
      }
    },
    table: {
      border: "solid black 1px",
      width: "100%",
      textAlign: "center",
      display: "flex",
      flexDirection: "column"
    },
    selected: {},
    input: {
      margin: theme.spacing(1)
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
      margin: "auto",
      width: "300px"
    },
    formControl: {
      padding: "25px",
      border: "solid black 1px",
      margin: "10px",
      minWidth: 220,
      textAlign: "center"
    },
    select: {
      margin: "5px",
      minWidth: 207
    },
    confirmation: {
      textAlign: "center",
      color: "green",
      paddingTop: "20px"
    },
    edit: {
      display: "flex",
      justifyItems: "flex-end"
    },
    title: {
      textAlign: "center"
    },
    service: {
      color: "red"
    },
    warning: {
      color: "orange"
    },
    menu: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginLeft: "40px"
    }
  })
);

const token = localStorage.getItem("token") || "";

export default function Administration() {
  const classes = useStyles();
  const [apiErrorLog, setApiErrorLog] = useState<FetchDBLog[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAllLog = () => {
    setLoading(true);
    fetch(`${DB_LOG_URL}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    })
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then(jsonData => {
              setApiErrorLog(jsonData);
            })
            .catch(error => {
              console.log(error);
            });
        }
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    fetchAllLog();
  }, []);

  const getLast24hErrorLog = () => {
    setLoading(true);
    fetch(`${LAST_24H_DB_LOG_URL}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    })
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then(jsonData => {
              setApiErrorLog(jsonData);
            })
            .catch(error => {
              console.log(error);
            });
        }
      })
      .then(() => setLoading(false));
  };

  const getLastMonthErrorLog = () => {
    setLoading(true);
    fetch(`${LAST_MONTH_DB_LOG_URL}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    })
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then(jsonData => {
              setApiErrorLog(jsonData);
            })
            .catch(error => {
              console.log(error);
            });
        }
      })
      .then(() => setLoading(false));
  };

  return (
    <div>
      <br />
      <Grid container className={classes.menu} justify="center" spacing={3}>
        <Grid item xs={12} sm={2}>
          <Button
            onClick={() => getLast24hErrorLog()}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Log des dernières 24 heures
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            onClick={() => getLastMonthErrorLog()}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Log du dernier mois
          </Button>
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            onClick={() => fetchAllLog()}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Tous les logs
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={3}>
        <Grid item xs={12} sm={4}>
          <Card className={classes.card}>
            <h1 className={classes.title}>
              <WarningTwoToneIcon className={classes.warning} />
              &nbsp; Liste des services redémarrés &nbsp;
              <WarningTwoToneIcon className={classes.warning} />
            </h1>
            {loading ? (
              <CircularDeterminate />
            ) : (
              apiErrorLog.map((servicesError, serviceErrorIdx: number) => {
                if (servicesError.data_type === "reset_services") {
                  return (
                    <div key={serviceErrorIdx}>
                      <h2>
                        Rapport d'erreur du &nbsp;
                        {dateIsoToLocale(servicesError.created_at)}
                      </h2>
                      {servicesError.notes &&
                        servicesError.notes.list &&
                        servicesError.notes.list.map(
                          (service: ServiceLog, serviceIdx: number) => {
                            return (
                              <ul key={serviceIdx}>
                                <div>
                                  <li>
                                    <span className={classes.service}>
                                      <b>[{service.environnement}]</b>
                                    </span>{" "}
                                    &nbsp;
                                    <a
                                      href={service.url}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <b>[{service.nom}]</b>
                                    </a>
                                  </li>

                                  <br />
                                </div>
                              </ul>
                            );
                          }
                        )}
                    </div>
                  );
                } else {
                  return null;
                }
              })
            )}
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card className={classes.card}>
            <h1 className={classes.title}>
              <ErrorIcon className={classes.service} />
              &nbsp; Liste des services en erreur &nbsp;
              <ErrorIcon className={classes.service} />
            </h1>

            {loading ? (
              <CircularDeterminate />
            ) : (
              apiErrorLog.map((servicesError, serviceErrorIdx: number) => {
                if (servicesError.data_type === "error_services") {
                  return (
                    <div key={serviceErrorIdx}>
                      <h2>
                        Rapport d'erreur du &nbsp;
                        {dateIsoToLocale(servicesError.created_at)}
                      </h2>

                      {servicesError.notes &&
                        servicesError.notes.list &&
                        servicesError.notes.list.map(
                          (service: ServiceLog, serviceIdx: number) => {
                            return (
                              <ul key={serviceIdx}>
                                <div>
                                  <li>
                                    <span className={classes.service}>
                                      <b>[{service.environnement}]</b>
                                    </span>
                                    &nbsp;
                                    <a
                                      href={service.url}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      <b>[{service.nom}]</b>
                                    </a>
                                    &nbsp;
                                    <span>
                                      actif pour la dernière fois{" "}
                                      <b>
                                        {service.alive
                                          ? `le ${dateIsoToLocale(
                                              service.alive
                                            )}`
                                          : `jamais`}
                                      </b>
                                    </span>
                                  </li>

                                  <br />
                                </div>
                              </ul>
                            );
                          }
                        )}
                    </div>
                  );
                } else {
                  return null;
                }
              })
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
