import React, { useState, useEffect, useContext } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Card from "@material-ui/core/Card";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import {
  FetchEnvironnement,
  FetchProject,
  FetchService
} from "../models/monitoring-models";
import ConfirmationDBDialog from "../components/Monitoring/ConfirmationDBDialog";
import {
  DELETE_ENVIRONNEMENT_URL,
  DELETE_PROJECT_URL,
  DB_LOG_URL,
  DELETE_SERVICE_URL,
  PROJETS_URL,
  ENVIRONNEMENTS_URL,
  SERVICES_URL,
  UNIQUE_PROJET_URL,
  UNIQUE_ENVIRONNEMENT_URL,
  UNIQUE_SERVICE_URL
} from "../routes";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../components/Auth/AuthContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 275,
      padding: "20px",
      marginTop: "50px"
    },
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    menuItem: {
      height: "175px",
      display: "flex"
    },
    item: {
      display: "flex",
      color: "black",
      "&$selected": {
        backgroundColor: "#66FF99"
      }
    },
    selected: {},
    input: {
      margin: theme.spacing(1)
    },
    button: {
      display: "block",
      marginTop: theme.spacing(2),
      margin: "auto"
    },
    formControl: {
      padding: "25px",
      border: "solid black 1px",
      margin: "10px",
      minWidth: 220,
      textAlign: "center"
    },
    select: {
      margin: "5px",
      minWidth: 207
    },
    confirmation: {
      textAlign: "center",
      color: "green",
      paddingTop: "20px"
    },
    edit: {
      display: "flex",
      justifyItems: "flex-end"
    },
    title: {
      textAlign: "center"
    }
  })
);

const token = localStorage.getItem("token") || "";

// A modifier une fois l'authentification par nom d'utilisateur faite
const user = "exyzt_user";

export default function Administration() {
  const classes = useStyles();
  const [idEnvironnement, setIdEnvironnement] = useState("");
  const [idService, setIdService] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [urlName, setUrlName] = useState("");
  const [apiEnvironnement, setApiEnvironnement] = useState<
    FetchEnvironnement[]
  >([]);
  const [apiProject, setApiProject] = useState<FetchProject[]>([]);
  const [idProject, setIdProject] = useState("");
  const [apiService, setApiService] = useState<FetchService[]>([]);
  const [isToggleProjectOn, setIsToggleProjectOn] = useState(false);
  const [isToggleEnvironnementOn, setIsToggleEnvironnementOn] = useState(false);
  const [isToggleServiceOn, setIsToggleServiceOn] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [environnementName, setEnvironnementName] = useState("");
  const [deleteClick, setDeleteClick] = useState(false);
  const [updateClick, setUpdateClick] = useState(false);
  const [updateProjectForm, setUpdateProjectForm] = useState(false);
  const [updateProject, setUpdateProject] = useState({ id: "", nom: "" });
  const [showService, setShowService] = useState(false);
  const [updateEnvironnement, setUpdateEnvironnement] = useState({
    id: "",
    nom: ""
  });
  const [updateEnvironnementForm, setUpdateEnvironnementForm] = useState(false);
  const [updateServiceForm, setUpdateServiceForm] = useState(false);
  const [updateService, setUpdateService] = useState({
    id: "",
    nom: "",
    url: ""
  });
  const [addClick, setAddClick] = useState(false);
  const [showConfirmationDBDialog, setShowConfirmationDBDialog] = useState({
    show: false,
    type: "",
    action: ""
  });
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const authContext = useContext(AuthContext);

  const getDate = () => {
    const now = new Date();
    const UTCParis = now.setHours(now.getHours() + 1);
    const updatedDate = new Date(UTCParis);
    const finaleDate = updatedDate.toISOString().slice(0, 19);
    return finaleDate.replace("T", " ");
  };

  const updateDBLog = (dataType: string) => {
    fetch(`${DB_LOG_URL}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        user_name: user,
        created_at: getDate(),
        data_type: dataType
      })
    });
  };

  const updateNewDBLog = (dataType: string, name: string) => {
    fetch(`${DB_LOG_URL}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        user_name: user,
        created_at: getDate(),
        data_type: dataType,
        notes: { nom: name }
      })
    });
  };

  const handleChangeEnvironnement = (event: any) => {
    setIdEnvironnement(event.target.value);
    setUpdateServiceForm(false);
    setShowService(true);
  };

  const handleChangeProject = (event: any) => {
    setIdProject(event.target.value);
    setUpdateEnvironnementForm(false);
    setShowService(false);
  };

  const handleChangeService = (event: any) => {
    setIdService(event.target.value);
  };

  const handleProjectUpdate = (id: string, nom: string) => {
    setUpdateProject({ id: id, nom: nom });
    setUpdateProjectForm(!updateProjectForm);
  };

  const handleEnvironnementUpdate = (id: string, nom: string) => {
    setUpdateEnvironnement({ id: id, nom: nom });
    setUpdateEnvironnementForm(!updateEnvironnementForm);
  };

  const handleServiceUpdate = (id: string, nom: string, url: string) => {
    setUpdateService({ id: id, nom: nom, url: url });
    setUpdateServiceForm(!updateServiceForm);
  };

  const handleToggleProject = () => {
    setIsToggleProjectOn(!isToggleProjectOn);
  };

  const handleToggleEnvironnement = () => {
    setIsToggleEnvironnementOn(!isToggleEnvironnementOn);
  };

  const handleToggleService = () => {
    setIsToggleServiceOn(!isToggleServiceOn);
  };

  const handleDeleteProjectConfirmation = (id: string, nom: string) => {
    setShowConfirmationDBDialog({
      show: true,
      type: "project",
      action: "delete"
    });
    setUpdateProject({ id: id, nom: nom });
  };

  const handleDeleteEnvironnementConfirmation = (id: string, nom: string) => {
    setShowConfirmationDBDialog({
      show: true,
      type: "environnement",
      action: "delete"
    });
    setUpdateEnvironnement({ id: id, nom: nom });
  };

  const handleDeleteServiceConfirmation = (
    id: string,
    nom: string,
    url: string
  ) => {
    setShowConfirmationDBDialog({
      show: true,
      type: "service",
      action: "delete"
    });
    setUpdateService({ id: id, nom: nom, url: url });
  };

  const handleUpdateProjectConfirmation = (id: string, nom: string) => {
    if (projectName === "") {
      setError(true);
      setErrorMessage("Champ vide, merci de saisir un nom de projet");
      setUpdateProjectForm(false);
    } else if (
      apiProject.filter(
        projet => projet.nom.toUpperCase() === projectName.toUpperCase()
      ).length > 0
    ) {
      setError(true);
      setErrorMessage(
        "Nom de projet déjà existant, merci de saisir un nom de projet différent"
      );
      setUpdateProjectForm(false);
    } else {
      setShowConfirmationDBDialog({
        show: true,
        type: "project",
        action: "update"
      });
      setUpdateProject({ id: id, nom: nom });
      setUpdateProjectForm(false);
    }
  };

  const handleUpdateEnvironnementConfirmation = (id: string, nom: string) => {
    if (environnementName === "") {
      setError(true);
      setErrorMessage("Champ vide, merci de saisir un nom d'environnement'");
      setUpdateEnvironnementForm(false);
    } else if (
      apiEnvironnement.filter(
        env => env.nom.toUpperCase() === environnementName.toUpperCase()
      ).length > 0
    ) {
      setError(true);
      setErrorMessage(
        "Nom d'environnement déjà existant, merci de saisir un nom d'environnement différent"
      );
      setUpdateEnvironnementForm(false);
    } else {
      setShowConfirmationDBDialog({
        show: true,
        type: "environnement",
        action: "update"
      });
      setUpdateEnvironnement({ id: id, nom: nom });
      setUpdateEnvironnementForm(false);
    }
  };

  const handleUpdateServiceConfirmation = (
    id: string,
    nom: string,
    url: string
  ) => {
    if (serviceName === "" || urlName === "") {
      setError(true);
      setErrorMessage("Champ vide, merci de saisir un nom d'environnement'");
      setUpdateServiceForm(false);
    } else if (
      apiService.filter(
        service =>
          service.id_service !== updateService.id &&
          service.nom.toUpperCase() === serviceName.toUpperCase()
      ).length > 0 ||
      apiService.filter(
        service =>
          service.id_service !== updateService.id &&
          service.url.toUpperCase() === urlName.toUpperCase()
      ).length > 0
    ) {
      setError(true);
      setErrorMessage(
        "URL ou nom de service déjà existant, merci de saisir un nom de service différent"
      );
      setUpdateServiceForm(false);
    } else {
      setShowConfirmationDBDialog({
        show: true,
        type: "service",
        action: "update"
      });
      setUpdateService({ id: id, nom: nom, url: url });
      setUpdateServiceForm(false);
    }
  };

  const handleError = () => {
    setError(false);
  };

  const handleSubmitProjectConfirmation = () => {
    if (projectName === "") {
      setError(true);
      setErrorMessage("Champ vide, merci de saisir un nom de projet");
      setIsToggleProjectOn(false);
    } else if (
      apiProject.filter(
        projet => projet.nom.toUpperCase() === projectName.toUpperCase()
      ).length > 0
    ) {
      setError(true);
      setErrorMessage(
        "Nom de projet déjà existant, merci de saisir un nom de projet différent"
      );
      setIsToggleProjectOn(false);
    } else {
      setShowConfirmationDBDialog({
        show: true,
        type: "project",
        action: "post"
      });
      setIsToggleProjectOn(false);
    }
  };

  const handleSubmitEnvironnementConfirmation = () => {
    if (environnementName === "") {
      setError(true);
      setErrorMessage("Champ vide, merci de saisir un nom d'environnement'");
      setIsToggleEnvironnementOn(false);
    } else if (
      apiEnvironnement.filter(
        env => env.nom.toUpperCase() === environnementName.toUpperCase()
      ).length > 0
    ) {
      setError(true);
      setErrorMessage(
        "Nom d'environnement déjà existant, merci de saisir un nom d'environnement différent"
      );
      setIsToggleEnvironnementOn(false);
    } else {
      setShowConfirmationDBDialog({
        show: true,
        type: "environnement",
        action: "post"
      });
      setIsToggleEnvironnementOn(false);
    }
  };

  const handleSubmitServiceConfirmation = () => {
    if (serviceName === "") {
      setError(true);
      setErrorMessage("Champ vide, merci de saisir un nom d'environnement'");
      setIsToggleServiceOn(false);
    } else if (
      apiService.filter(
        service => service.nom.toUpperCase() === serviceName.toUpperCase()
      ).length > 0 ||
      apiService.filter(
        service => service.url.toUpperCase() === urlName.toUpperCase()
      ).length > 0
    ) {
      setError(true);
      setErrorMessage(
        "URL ou nom de service déjà existant, merci de saisir un nom de service différent"
      );
      setIsToggleServiceOn(false);
    } else {
      setShowConfirmationDBDialog({
        show: true,
        type: "service",
        action: "post"
      });
      setIsToggleServiceOn(false);
    }
  };

  const handleDeleteProject = (id: string) => {
    fetch(`${DELETE_PROJECT_URL}/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    });
    setDeleteClick(!deleteClick);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    updateDBLog(`delete_project_${id}`);
  };

  const handleDeleteEnvironnement = (id: string) => {
    fetch(`${DELETE_ENVIRONNEMENT_URL}/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    });
    setDeleteClick(!deleteClick);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    updateDBLog(`delete_environnement_${id}`);
  };

  const handleDeleteService = (id: string) => {
    fetch(`${DELETE_SERVICE_URL}/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    });
    setDeleteClick(!deleteClick);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    updateDBLog(`delete_service_${id}`);
  };

  const handleUpdateProject = (id: string) => {
    fetch(`${UNIQUE_PROJET_URL}/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        nom: projectName
      })
    });
    setUpdateClick(!updateClick);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    updateDBLog(`update_project_${id}`);
  };

  const handleUpdateEnvironnement = (id: string) => {
    fetch(`${UNIQUE_ENVIRONNEMENT_URL}/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        nom: environnementName
      })
    });
    setUpdateClick(!updateClick);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    updateDBLog(`update_environnement_${id}`);
  };

  const handleUpdateService = (id: string) => {
    fetch(`${UNIQUE_SERVICE_URL}/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        nom: serviceName,
        url: urlName
      })
    });
    setUpdateClick(!updateClick);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    updateDBLog(`update_service_${id}`);
  };

  const handleSubmitProject = () => {
    fetch(`${UNIQUE_PROJET_URL}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        nom: projectName
      })
    });
    setIsToggleProjectOn(false);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    setAddClick(!addClick);
    updateNewDBLog(`submit_new_project`, projectName);
  };

  const handleSubmitEnvironnement = () => {
    fetch(`${ENVIRONNEMENTS_URL}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        id_projet: idProject,
        nom: environnementName
      })
    });
    setIsToggleEnvironnementOn(false);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    setAddClick(!addClick);
    updateNewDBLog(`submit_new_environnement`, environnementName);
  };

  const handleSubmitService = () => {
    fetch(`${UNIQUE_SERVICE_URL}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        // eslint-disable-next-line @typescript-eslint/camelcase
        id_environnement: idEnvironnement,
        nom: serviceName,
        url: urlName
      })
    });
    setIsToggleServiceOn(false);
    setShowConfirmationDBDialog({ show: false, type: "", action: "" });
    setAddClick(!addClick);
    updateNewDBLog(`submit_new_service`, serviceName);
  };

  useEffect(() => {
    fetch(`${PROJETS_URL}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    }).then(response => {
      if (response.ok) {
        response
          .json()
          .then(jsonData => {
            setApiProject(jsonData);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }, [idProject, updateClick, addClick, deleteClick]);

  useEffect(() => {
    fetch(`${ENVIRONNEMENTS_URL}/${idProject}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    }).then(response => {
      if (response.ok) {
        response
          .json()
          .then(jsonData => {
            setApiEnvironnement(jsonData);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }, [idProject, idEnvironnement, updateClick, addClick, deleteClick]);

  useEffect(() => {
    fetch(`${SERVICES_URL}/${idEnvironnement}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    }).then(response => {
      if (response.ok) {
        response
          .json()
          .then(jsonData => {
            setApiService(jsonData);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }, [idProject, idEnvironnement, updateClick, addClick, deleteClick]);

  return (
    <div>
      {authContext.login.isLogged ? (
        <div>
          <br />

          {/* Alerte pour confirmer la validation des requêtes à la base */}

          {error ? (
            <ConfirmationDBDialog callback={handleError} error={errorMessage} />
          ) : null}

          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "project" &&
          showConfirmationDBDialog.action === "delete" ? (
            <ConfirmationDBDialog
              callback={handleDeleteProject}
              id={updateProject.id}
              message={`Voulez-vous supprimer "${updateProject.nom}" ainsi que tous ses environnements et services associés ?`}
            />
          ) : null}
          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "environnement" &&
          showConfirmationDBDialog.action === "delete" ? (
            <ConfirmationDBDialog
              callback={handleDeleteEnvironnement}
              id={updateEnvironnement.id}
              message={`Voulez-vous supprimer "${updateEnvironnement.nom}" ainsi que tous ses services associés ?`}
            />
          ) : null}
          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "service" &&
          showConfirmationDBDialog.action === "delete" ? (
            <ConfirmationDBDialog
              callback={handleDeleteService}
              id={updateService.id}
              message={`Voulez-vous supprimer le service"${updateService.nom}" ?`}
            />
          ) : null}
          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "project" &&
          showConfirmationDBDialog.action === "update" ? (
            <ConfirmationDBDialog
              callback={handleUpdateProject}
              id={updateProject.id}
              message={`Voulez-vous mettre à jour le projet "${updateProject.nom}" ?`}
            />
          ) : null}
          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "environnement" &&
          showConfirmationDBDialog.action === "update" ? (
            <ConfirmationDBDialog
              callback={handleUpdateEnvironnement}
              id={updateEnvironnement.id}
              message={`Voulez-vous mettre à jour l'environnement "${updateEnvironnement.nom}" ?`}
            />
          ) : null}
          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "service" &&
          showConfirmationDBDialog.action === "update" ? (
            <ConfirmationDBDialog
              callback={handleUpdateService}
              id={updateService.id}
              message={`Voulez-vous mettre à jour le service "${updateService.nom}" ?`}
            />
          ) : null}
          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "project" &&
          showConfirmationDBDialog.action === "post" ? (
            <ConfirmationDBDialog
              callback={handleSubmitProject}
              message={`Voulez-vous ajouter le projet "${projectName}"?`}
            />
          ) : null}
          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "environnement" &&
          showConfirmationDBDialog.action === "post" ? (
            <ConfirmationDBDialog
              callback={handleSubmitEnvironnement}
              message={`Voulez-vous ajouter l'environnement "${environnementName}" ?`}
            />
          ) : null}
          {showConfirmationDBDialog.show &&
          showConfirmationDBDialog.type === "service" &&
          showConfirmationDBDialog.action === "post" ? (
            <ConfirmationDBDialog
              callback={handleSubmitService}
              message={`Voulez-vous ajouter le service "${serviceName}" ?`}
            />
          ) : null}

          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} sm={3}>
              <Card className={classes.card}>
                <h1 className={classes.title}>Produit</h1>
                {apiProject.map(project => {
                  return (
                    <div key={project.id_projet}>
                      <Grid container spacing={1}>
                        <Grid item xs={10} sm={8}>
                          <MenuItem
                            classes={{
                              root: classes.item,
                              selected: classes.selected
                            }}
                            className={classes.item}
                            selected={project.id_projet === idProject}
                            key={project.id_projet}
                            value={project.id_projet}
                            onClick={handleChangeProject}
                          >
                            {project.nom}
                          </MenuItem>
                        </Grid>
                        <Grid item xs={1} sm={2}>
                          <Button
                            onClick={() =>
                              handleProjectUpdate(
                                project.id_projet,
                                project.nom
                              )
                            }
                            variant="contained"
                            color="primary"
                          >
                            <EditIcon />
                          </Button>
                        </Grid>
                        <Grid item xs={1} sm={2}>
                          <Button
                            onClick={() =>
                              handleDeleteProjectConfirmation(
                                project.id_projet,
                                project.nom
                              )
                            }
                            variant="contained"
                            color="secondary"
                          >
                            <DeleteForeverIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}

                {updateProjectForm ? (
                  <form
                    onSubmit={() =>
                      handleUpdateProjectConfirmation(
                        updateProject.id,
                        updateProject.nom
                      )
                    }
                    className={classes.formControl}
                  >
                    <Input
                      name="project"
                      onChange={event => setProjectName(event.target.value)}
                      placeholder={updateProject.nom}
                      className={classes.input}
                      inputProps={{
                        "aria-label": "description"
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                    >
                      Modifier le Produit
                    </Button>
                  </form>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleToggleProject}
                >
                  +
                </Button>
                {isToggleProjectOn ? (
                  <div>
                    <form
                      onSubmit={() => handleSubmitProjectConfirmation()}
                      className={classes.formControl}
                    >
                      <Grid item xs={12} sm={12}>
                        <Input
                          name="project"
                          onChange={event => setProjectName(event.target.value)}
                          placeholder="nom"
                          className={classes.input}
                          inputProps={{
                            "aria-label": "description"
                          }}
                        />
                      </Grid>
                      <br />
                      <Grid item xs={12} sm={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                        >
                          Ajouter un projet
                        </Button>
                      </Grid>
                    </form>
                  </div>
                ) : null}
              </Card>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Card className={classes.card}>
                <h1 className={classes.title}>Environnement</h1>
                {idProject
                  ? apiEnvironnement.map(
                      (environnement, environnementIdx: number) => {
                        return (
                          <Grid key={environnementIdx} container spacing={1}>
                            <Grid item xs={10} sm={8}>
                              <MenuItem
                                classes={{
                                  root: classes.item,
                                  selected: classes.selected
                                }}
                                selected={
                                  environnement.id_environnement ===
                                  idEnvironnement
                                }
                                key={environnement.id_environnement}
                                value={environnement.id_environnement}
                                onClick={handleChangeEnvironnement}
                              >
                                {environnement.nom}
                              </MenuItem>
                            </Grid>
                            <Grid item xs={1} sm={2}>
                              <Button
                                onClick={() =>
                                  handleEnvironnementUpdate(
                                    environnement.id_environnement,
                                    environnement.nom
                                  )
                                }
                                className={classes.edit}
                                variant="contained"
                                color="primary"
                              >
                                <EditIcon />
                              </Button>
                            </Grid>
                            <Grid item xs={1} sm={2}>
                              <Button
                                onClick={() =>
                                  handleDeleteEnvironnementConfirmation(
                                    environnement.id_environnement,
                                    environnement.nom
                                  )
                                }
                                variant="contained"
                                color="secondary"
                              >
                                <DeleteForeverIcon />
                              </Button>
                            </Grid>
                          </Grid>
                        );
                      }
                    )
                  : null}

                {updateEnvironnementForm ? (
                  <form
                    onSubmit={() =>
                      handleUpdateEnvironnementConfirmation(
                        updateEnvironnement.id,
                        updateEnvironnement.nom
                      )
                    }
                    className={classes.formControl}
                  >
                    <Input
                      name="environnement"
                      onChange={event =>
                        setEnvironnementName(event.target.value)
                      }
                      placeholder={updateEnvironnement.nom}
                      className={classes.input}
                      inputProps={{
                        "aria-label": "description"
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                    >
                      Modifier l'environnement
                    </Button>
                  </form>
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleToggleEnvironnement}
                >
                  +
                </Button>
                {isToggleEnvironnementOn ? (
                  <form
                    onSubmit={() => handleSubmitEnvironnementConfirmation()}
                    className={classes.formControl}
                  >
                    <Input
                      name="environnement"
                      onChange={event =>
                        setEnvironnementName(event.target.value)
                      }
                      placeholder="nom"
                      className={classes.input}
                      inputProps={{
                        "aria-label": "description"
                      }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                    >
                      Ajouter un environnement
                    </Button>
                  </form>
                ) : null}
              </Card>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Card className={classes.card}>
                <h1 className={classes.title}>Service</h1>
                {showService ? (
                  <div>
                    {idEnvironnement
                      ? apiService.map((service, serviceIdx: number) => {
                          return (
                            <Grid key={serviceIdx} container spacing={1}>
                              <Grid item xs={10} sm={8}>
                                <MenuItem
                                  classes={{
                                    root: classes.item,
                                    selected: classes.selected
                                  }}
                                  selected={service.id_service === idService}
                                  key={service.id_service}
                                  value={service.id_service}
                                  onClick={handleChangeService}
                                >
                                  {service.nom}
                                </MenuItem>
                              </Grid>

                              <Grid item xs={1} sm={2}>
                                <Button
                                  onClick={() =>
                                    handleServiceUpdate(
                                      service.id_service,
                                      service.nom,
                                      service.url
                                    )
                                  }
                                  className={classes.edit}
                                  variant="contained"
                                  color="primary"
                                >
                                  <EditIcon />
                                </Button>
                              </Grid>
                              <Grid item xs={1} sm={2}>
                                <Button
                                  onClick={() =>
                                    handleDeleteServiceConfirmation(
                                      service.id_service,
                                      service.nom,
                                      service.url
                                    )
                                  }
                                  variant="contained"
                                  color="secondary"
                                >
                                  <DeleteForeverIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        })
                      : null}
                    {updateServiceForm ? (
                      <form
                        onSubmit={() =>
                          handleUpdateServiceConfirmation(
                            updateService.id,
                            updateService.nom,
                            updateService.url
                          )
                        }
                        className={classes.formControl}
                      >
                        <Input
                          name="service"
                          onChange={event => setServiceName(event.target.value)}
                          placeholder={updateService.nom}
                          className={classes.input}
                          inputProps={{
                            "aria-label": "description"
                          }}
                        />
                        <Input
                          name="url"
                          onChange={event => setUrlName(event.target.value)}
                          placeholder={updateService.url}
                          className={classes.input}
                          inputProps={{
                            "aria-label": "description"
                          }}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          type="submit"
                        >
                          Modifier le Service
                        </Button>
                      </form>
                    ) : null}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={handleToggleService}
                    >
                      +
                    </Button>
                    {isToggleServiceOn ? (
                      <div>
                        <form
                          onSubmit={() => handleSubmitServiceConfirmation()}
                          className={classes.formControl}
                        >
                          <Grid item xs={12} sm={12}>
                            <Input
                              name="service"
                              onChange={event =>
                                setServiceName(event.target.value)
                              }
                              placeholder="nom"
                              className={classes.input}
                              inputProps={{
                                "aria-label": "description"
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <Input
                              name="url"
                              onChange={event => setUrlName(event.target.value)}
                              placeholder="url"
                              className={classes.input}
                              inputProps={{
                                "aria-label": "description"
                              }}
                            />
                          </Grid>
                          <br />
                          <Grid item xs={12} sm={12}>
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              type="submit"
                            >
                              Ajouter un Service
                            </Button>
                          </Grid>
                        </form>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </Card>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <Redirect to="/login" />
        </div>
      )}
    </div>
  );
}
