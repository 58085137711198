import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { TableauUrl } from "../../models/monitoring-models";
import CircularDeterminate from "./CircularDeterminate";
import SpeedIcon from "@material-ui/icons/Speed";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 345,
      backgroundColor: "#EEEEEE",
      height: 295
    },
    cardhead: {
      textAlign: "center"
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatarHealth: {
      backgroundColor: "green",
      margin: "auto"
    },
    avatarError: {
      backgroundColor: "red",
      margin: "auto",
      animation: "$blinkingAvatar 2s infinite"
    },
    "@keyframes blinkingAvatar": {
      "0%": { backgroundColor: "red" },
      "25%": { backgroundColor: "red" },
      "50%": { backgroundColor: "white" },
      "75%": { backgroundColor: "red" },
      "100%": { backgroundColor: "red" }
    },
    avatarOrange: {
      backgroundColor: "orange",
      margin: "auto"
    },
    avatarMaroon: {
      backgroundColor: "maroon",
      margin: "auto"
    },
    cardContent: {
      textAlign: "center",
      marginLeft: "auto"
    },
    showStatus: {
      margin: "auto"
    },
    subheader: {
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  })
);

interface MonitoringItemProps {
  title: string;
  subheader: any;
  subheaderLoading?: any;
  servicesList: TableauUrl[];
  erreur?: boolean | string;
  handleClick?(project: string): void;
}

interface MonitoringItemState {
  // status: boolean;
}

export default function MonitoringItem(
  props: MonitoringItemProps,
  state: MonitoringItemState
) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader
        classes={{
          subheader: classes.subheader
        }}
        className={classes.cardhead}
        title={props.title}
        subheader={props.subheader}
      />
      <div className={classes.subheader}>{props.subheaderLoading}</div>
      <CardContent className={classes.cardContent}>
        <div className={classes.showStatus}>
          Status
          <br />
          <SpeedIcon />
          {props.erreur === undefined && <CircularDeterminate />}
          {props.erreur === "maroon" && (
            <Avatar
              aria-label="recipe"
              className={classes.avatarMaroon}
            ></Avatar>
          )}
          {props.erreur === "orange" && (
            <Avatar
              aria-label="recipe"
              className={classes.avatarOrange}
            ></Avatar>
          )}
          {!props.erreur && (
            <Avatar
              aria-label="recipe"
              className={classes.avatarHealth}
            ></Avatar>
          )}
          {props.erreur && (
            <Avatar
              aria-label="recipe"
              className={classes.avatarError}
            ></Avatar>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
