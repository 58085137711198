import React, { useContext } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import MonitoringContext from "../components/Monitoring/MonitoringContext";
import Grid from "@material-ui/core/Grid";
import MonitoringItem from "../components/Monitoring/MonitoringItem";
import Legende from "../components/Monitoring/Legende";
import { serviceError, serviceLoading } from "../services/monitoring-service";
import ServiceHover from "../components/Monitoring/ServiceHover";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    link: {
      textDecoration: "none"
    },
    grid: {
      display: "flex",
      justifyContent: "center",
      padding: "50px",
      margin: "50px"
    }
  })
);

export default function MonitoringDashboard() {
  const classes = useStyles();
  const monitoringContext = useContext(MonitoringContext);

  const handleProjectChanged = (project: string) => {
    //   console.log(project)
  };

  return (
    <div>
      <Legende />
      <br />

      <Grid className={classes.grid} container justify="center" spacing={3}>
        {monitoringContext.map(projet => {
          return (
            <Grid key={projet.project} item xs={12} sm={3}>
              <Link
                key={projet.project}
                className={classes.link}
                to={projet.project}
              >
                <div onClick={() => handleProjectChanged(projet.project)}>
                  <MonitoringItem
                    key={projet.project}
                    title={projet.project.toUpperCase()}
                    subheader={[
                      <ServiceHover
                        key="serviceOK"
                        type="serviceOK"
                        project={projet}
                      />,
                      " \xa0 ",
                      <ServiceHover
                        key="error"
                        type="error"
                        project={projet}
                      />,
                      " \xa0 ",
                      <ServiceHover
                        key="orange"
                        type="orange"
                        project={projet}
                      />,
                      " \xa0 ",
                      <ServiceHover
                        key="maroon"
                        type="maroon"
                        project={projet}
                      />,
                      " \xa0 ",
                      <ServiceHover key="blue" type="blue" project={projet} />
                    ]}
                    subheaderLoading={[
                      serviceLoading(projet) === 0 ? null : (
                        <ServiceHover
                          key="loading"
                          type="loading"
                          project={projet}
                        />
                      ),
                      serviceLoading(projet) === 0
                        ? null
                        : `En cours : ${serviceLoading(projet)}`
                    ]}
                    servicesList={[]}
                    erreur={serviceError(projet) ? true : false}
                  ></MonitoringItem>
                </div>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
