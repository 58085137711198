import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Login from "../components/Monitoring/Login";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "red",
      padding: "25px"
    }
  })
);

export default function MonitoringLogin() {
  const classes = useStyles();

  return (
    <div>
      <br />
      <Grid container justify="center" spacing={3}>
        <Card className={classes.root}>
          <Login />
        </Card>
      </Grid>
    </div>
  );
}
