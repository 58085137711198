import React, { useContext, useState, useMemo } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import MonitoringDashboard from "../../controllers/MonitoringDashboard";
import MonitoringDrawer from "./MonitoringDrawer";
import MonitoringProject from "../../controllers/MonitoringProject";
import MonitoringContext from "./MonitoringContext";
import { AuthContext } from "../Auth/AuthContext";
import MonitoringEnvironment from "../../controllers/MonitoringEnvironment";
import MonitoringLogin from "../../controllers/MonitoringLogin";
import MonitoringRegister from "../../controllers/MonitoringRegister";
import Administration from "../../controllers/Administration";
import MonitoringLog from "../../controllers/MonitoringLog";
import PrivateRoute from "../../services/PrivateRoute";
import ProtectedRoute from "../../services/ProtectedRoute";
import { ENABLE_REGISTRATION } from "../../secrets";

export default function MonitoringRouter() {
  const monitoringContext = useContext(MonitoringContext);
  const [login, setLogin] = useState({
    isLogged: false,
    userName: "",
    admin: false
  });
  const value = useMemo(() => ({ login, setLogin }), [login, setLogin]);

  return (
    <BrowserRouter>
      <AuthContext.Provider value={value}>
        <Route path="/" component={MonitoringDrawer} />
        <Route exact path="/login" component={MonitoringLogin} />
        {ENABLE_REGISTRATION && (
          <Route exact path="/register" component={MonitoringRegister} />
        )}
        <PrivateRoute exact path="/" component={MonitoringDashboard} />

        {monitoringContext.length > 0
          ? monitoringContext
              .filter(project => project.projectEnvironmement !== null)
              .map((project, projectIdx: number) => {
                return (
                  <Route
                    key={`/${projectIdx}`}
                    path={`/${project.project}`}
                    render={props => (
                      <MonitoringProject {...props} project={project.project} />
                    )}
                  />
                );
              })
          : null}

        {monitoringContext.length > 0
          ? monitoringContext
              .filter(project => project.projectEnvironmement !== null)
              .map(projet =>
                projet.projectEnvironmement.map(
                  (services, servicesIdx: number) => {
                    return (
                      <Route
                        key={`/${projet.project}-services/${servicesIdx}`}
                        path={`/${projet.project}-services/${services.environement}`}
                        render={props => (
                          <MonitoringEnvironment
                            {...props}
                            project={projet.project}
                            service={services.environement}
                          />
                        )}
                      />
                    );
                  }
                )
              )
          : null}

        <PrivateRoute exact path="/log" component={MonitoringLog} />

        <ProtectedRoute
          exact
          path="/administration"
          component={Administration}
        />
      </AuthContext.Provider>
    </BrowserRouter>
  );
}
