import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { AuthContext } from "../components/Auth/AuthContext";

type ProtectedRouteProps = RouteProps & {
  fallback?: string;
};

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { fallback = "/", ...routeProps } = props;
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { login } = useContext(AuthContext);

  const admin = localStorage.getItem("admin" || "");

  const adminCheck = () => {
    login.isLogged && admin === "true" ? setIsAdmin(true) : setIsAdmin(false);
  };

  useEffect(() => {
    adminCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.admin]);

  // TODO Check why the Redirect isn't working
  return (
    <>{isAdmin ? <Route {...routeProps} /> : <Redirect to={fallback} />}</>
  );
}
