import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      color: "green"
    },
    iconOrange: {
      color: "orange"
    },
    iconMaroon: {
      color: "maroon"
    },
    iconBlue: {
      color: "blue"
    },
    legende: {
      paddingLeft: 10,
      fontSize: 15,
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    }
  })
);

export default function Legende() {
  const classes = useStyles();

  return (
    <Grid container justify="center">
      <div className={classes.legende}>
        <CheckCircleIcon key="check" className={classes.icon} />
        Service OK
      </div>
      <div className={classes.legende}>
        <ErrorIcon key="error" color="error" />
        Service en Erreur
      </div>
      <div className={classes.legende}>
        <AccessAlarmsIcon key="iconOrange" className={classes.iconOrange} />
        Service redémarré sous 48h
      </div>
      <div className={classes.legende}>
        <AccessAlarmsIcon key="iconMaroon" className={classes.iconMaroon} />
        Service redémarré sous 2h
      </div>
      <div className={classes.legende}>
        <AccessAlarmsIcon key="iconMaroon" className={classes.iconBlue} />
        Service en attente de redémarrage
      </div>
    </Grid>
  );
}
