import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import { TableauUrl } from "../../models/monitoring-models";
import CircularDeterminate from "./CircularDeterminate";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: 100,
      display: "flex",
      flex: 1,
      maxWidth: 345,
      backgroundColor: "#EEEEEE"
    },
    title: {
      fontSize: "16px",
      overflowWrap: "anywhere"
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatarHealth: {
      backgroundColor: "green",
      margin: "auto "
    },
    avatarError: {
      backgroundColor: "red",
      margin: "auto ",
      animation: "$blinkingAvatar 2s infinite"
    },
    "@keyframes blinkingAvatar": {
      "0%": { backgroundColor: "red" },
      "25%": { backgroundColor: "red" },
      "50%": { backgroundColor: "white" },
      "75%": { backgroundColor: "red" },
      "100%": { backgroundColor: "red" }
    },
    avatarOrange: {
      backgroundColor: "orange",
      margin: "auto "
    },
    avatarMaroon: {
      backgroundColor: "maroon",
      margin: "auto "
    },
    avatarBlue: {
      backgroundColor: "blue",
      margin: "auto "
    },
    cardContent: {
      textAlign: "center",
      marginLeft: "auto"
    },
    showStatus: {
      display: "flex"
    },
    subheader: {
      fontSize: 10
    },
    cardheader: {
      display: "flex"
    }
  })
);

interface MonitoringDisplayServiceProps {
  title: string;
  subheader: any;
  servicesList: TableauUrl[];
  erreur?: boolean | string;
}

interface MonitoringDisplayServiceState {
  // status: boolean;
}

export default function MonitoringDisplayService(
  props: MonitoringDisplayServiceProps,
  state: MonitoringDisplayServiceState
) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Grid container spacing={3}>
        <Grid item xs={3} sm={3} className={classes.showStatus}>
          {props.erreur === undefined && <CircularDeterminate />}
          {props.erreur === "maroon" && (
            <Avatar
              aria-label="recipe"
              className={classes.avatarMaroon}
            ></Avatar>
          )}
          {props.erreur === "orange" && (
            <Avatar
              aria-label="recipe"
              className={classes.avatarOrange}
            ></Avatar>
          )}
          {props.erreur === "blue" && (
            <Avatar aria-label="recipe" className={classes.avatarBlue}></Avatar>
          )}
          {props.erreur === false && (
            <Avatar
              aria-label="recipe"
              className={classes.avatarHealth}
            ></Avatar>
          )}
          {props.erreur === true && (
            <Avatar
              aria-label="recipe"
              className={classes.avatarError}
            ></Avatar>
          )}
        </Grid>
        <Grid className={classes.cardheader} item xs={9} sm={9}>
          <CardHeader
            classes={{ title: classes.title, subheader: classes.subheader }}
            title={props.title}
            subheader={props.subheader}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
