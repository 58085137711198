import React from "react";
import { ListItem, ListItemText } from "@material-ui/core";

interface MonitoringHoverProps {
  title: string;
  subheader: any;
}

interface MonitoringHoverState {
  // status: boolean;
}

export default React.memo(function MonitoringHover(
  props: MonitoringHoverProps,
  state: MonitoringHoverState
) {
  return (
    <ListItem>
      <ListItemText primary={props.title} />
    </ListItem>
  );
});
