import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../Auth/AuthContext";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { LOGIN, ADMIN } from "../../routes";
import ErrorModal from "../Utils/ErrorModal";
import { ENABLE_REGISTRATION } from "../../secrets";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  title: {
    color: "red"
  },
  paperError: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "12px solid red",
    padding: theme.spacing(2, 4, 3)
  }
}));

const token = localStorage.getItem("token") || "";

export default function SignIn() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const { login, setLogin } = useContext(AuthContext);
  const [errorLogin, setErrorLogin] = useState(false);

  // Callback effectué sur <ErrorModal />
  const handleError = () => {
    setErrorLogin(false);
  };

  useEffect(() => {
    if (login.token === undefined) return;
    localStorage.setItem("username", username);
  }, [login.token, username]);

  useEffect(() => {
    if (login.token === undefined) return;
    localStorage.setItem("token", `Bearer ${login.token}`);
  }, [login.token]);

  // Hook from React Router to Redirect once Auth is confirmed
  const history = useHistory();

  const body = "Identifiant ou mot de passe erroné !";

  //TODO
  const handleLogin = (event: any, username: string, userPassword: string) => {
    event.preventDefault();
    fetch(`${LOGIN}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        userName: username,
        userPassword: userPassword
      })
    }).then(response => {
      if (response.ok) {
        response.json().then(jsonData => {
          setLogin({
            isLogged: true,
            userName: username,
            token: jsonData
          });
        });
      } else {
        console.log("Login error");
        setErrorLogin(true);
      }
    });
  };

  // if Auth confirmed then redirect to home
  if (login.isLogged) {
    history.push("/");
    // return <Auth isAuthentified={isAuth} />;
  }

  // FAIRE USEEFFECT to check if loingis true then fecth to get admin check
  useEffect(() => {
    fetch(`${ADMIN}/${username}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    }).then(response => {
      if (response.ok) {
        response.json().then(jsonData => {
          localStorage.setItem("admin", jsonData.admin);
          setLogin({
            isLogged: true,
            userName: username,
            token: token,
            admin: jsonData.admin
          });
        });
      } else {
        console.log("not an admin");
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.isLogged]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Se connecter
        </Typography>
        <form
          onSubmit={event => handleLogin(event, username, userPassword)}
          className={classes.form}
          // noValidate
        >
          <TextField
            onChange={event => setUsername(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="identifiant"
            label="identifiant"
            name="identifiant"
            autoComplete="identifiant"
            autoFocus
          />
          <TextField
            onChange={event => setUserPassword(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de Passe"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Restez connecté"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Se connecter
          </Button>
          {ENABLE_REGISTRATION && (
            <Grid container>
              <Grid item>
                <Link to="/register" variant="body2" component={RouterLink}>
                  {"Vous n'avez pas de compte ? S'enregistrer"}
                </Link>
              </Grid>
            </Grid>
          )}
        </form>
      </div>
      <Box mt={8}></Box>
      {errorLogin ? <ErrorModal handleError={handleError} body={body} /> : null}
    </Container>
  );
}
