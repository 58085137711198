import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { EnvURL, Project } from "../../models/monitoring-models";
import MonitoringHover from "./MonitoringHover";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CircularDeterminate from "./CircularDeterminate";
import {
  serviceOK,
  serviceError,
  serviceOrange,
  serviceMaroon,
  serviceBlue
} from "../../services/monitoring-service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row"
    },
    card: {
      maxWidth: 345,
      backgroundColor: "#EEEEEE"
    },
    cardhead: {
      textAlign: "center"
    },
    media: {
      height: 0,
      paddingTop: "56.25%" // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    avatarHealth: {
      backgroundColor: "green",
      margin: "auto"
    },
    avatarError: {
      backgroundColor: "red",
      margin: "auto"
    },
    avatarOrange: {
      backgroundColor: "orange",
      margin: "auto"
    },
    avatarMaroon: {
      backgroundColor: "maroon",
      margin: "auto"
    },
    cardContent: {
      textAlign: "center",
      marginLeft: "auto"
    },
    showStatus: {
      margin: "auto"
    },
    icon: {
      color: "green"
    },
    iconOrange: {
      color: "orange"
    },
    iconMaroon: {
      color: "maroon"
    },
    iconBlue: {
      color: "blue"
    },
    popper: {
      height: "24em",
      overflow: "auto "
    },
    tooltip: {
      backgroundColor: "white"
    },
    link: {
      textDecoration: "none"
    }
  })
);

interface ServiceHoverProps {
  type: string;
  project: Project;
}

interface ServiceHoverState {
  // status: boolean;
}

export default function ServiceHover(
  props: ServiceHoverProps,
  state: ServiceHoverState
) {
  const classes = useStyles();

  const ServiceError = () => {
    return (
      <div className={classes.root}>
        <Tooltip
          interactive
          classes={{
            popper: classes.popper
          }}
          title={
            props.project.projectEnvironmement &&
            props.project.projectEnvironmement.map(
              (services: EnvURL) =>
                services.list &&
                services.list.map(service => {
                  if (service.fetchInfos && "error" in service.fetchInfos)
                    return (
                      <MonitoringHover
                        key={service.url}
                        title={service.nom}
                        subheader=""
                      />
                    );
                  else {
                    return null;
                  }
                })
            )
          }
        >
          <ErrorIcon color="error" />
        </Tooltip>
        <div> {serviceError(props.project)}</div>
      </div>
    );
  };
  const ServiceLoading = () => {
    return (
      <Tooltip
        interactive
        classes={{
          popper: classes.popper
        }}
        title={
          props.project.projectEnvironmement &&
          props.project.projectEnvironmement.map(
            (services: EnvURL) =>
              services.list &&
              services.list.map(service => {
                if (service.fetchInfos === undefined)
                  return (
                    <MonitoringHover
                      key={service.url}
                      title={service.nom}
                      subheader=""
                    />
                  );
                else {
                  return null;
                }
              })
          )
        }
      >
        <div>
          <CircularDeterminate />
        </div>
      </Tooltip>
    );
  };

  const ServiceTwoDays = () => {
    return (
      <div className={classes.root}>
        <Tooltip
          title={
            props.project.projectEnvironmement &&
            props.project.projectEnvironmement.map(
              (services: EnvURL) =>
                services.list &&
                services.list.map(service => {
                  if (
                    service.fetchInfos &&
                    "currentDate" in service.fetchInfos &&
                    parseInt(service.fetchInfos.aliveSince) <= 172800000 &&
                    parseInt(service.fetchInfos.aliveSince) >= 7200000
                  )
                    return (
                      <MonitoringHover
                        key={service.url}
                        title={service.nom}
                        subheader=""
                      />
                    );
                  else {
                    return null;
                  }
                })
            )
          }
        >
          <AccessAlarmsIcon className={classes.iconOrange} />
        </Tooltip>
        <div>{serviceOrange(props.project)}</div>
      </div>
    );
  };

  const ServiceOK = () => {
    return (
      <div className={classes.root}>
        <CheckCircleIcon className={classes.icon} />
        <div>{serviceOK(props.project)}</div>
      </div>
    );
  };

  const ServiceTwoHours = (): any => {
    return (
      <div className={classes.root}>
        <Tooltip
          title={
            props.project.projectEnvironmement &&
            props.project.projectEnvironmement.map(
              (services: EnvURL) =>
                services.list &&
                services.list.map(service => {
                  if (
                    service.fetchInfos &&
                    "currentDate" in service.fetchInfos &&
                    parseInt(service.fetchInfos.aliveSince) > 0 &&
                    parseInt(service.fetchInfos.aliveSince) <= 7200000
                  )
                    return (
                      <MonitoringHover
                        key={service.url}
                        title={service.nom}
                        subheader=""
                      />
                    );
                  else {
                    return null;
                  }
                })
            )
          }
        >
          <AccessAlarmsIcon className={classes.iconMaroon} />
        </Tooltip>
        <div>{serviceMaroon(props.project)}</div>
      </div>
    );
  };

  const ServiceReset = (): any => {
    return (
      <div className={classes.root}>
        <Tooltip
          title={
            props.project.projectEnvironmement &&
            props.project.projectEnvironmement.map(
              (services: EnvURL) =>
                services.list &&
                services.list.map(service => {
                  if (
                    service.fetchInfos &&
                    "waiting" in service.fetchInfos &&
                    service.fetchInfos.waiting &&
                    parseInt(service.fetchInfos.waiting) > 100
                  )
                    return (
                      <MonitoringHover
                        key={service.url}
                        title={service.nom}
                        subheader=""
                      />
                    );
                  else {
                    return null;
                  }
                })
            )
          }
        >
          <AccessAlarmsIcon className={classes.iconBlue} />
        </Tooltip>
        <div>{serviceBlue(props.project)}</div>
      </div>
    );
  };

  const ServiceState = (type: string) => {
    switch (type) {
      case "serviceOK":
        return ServiceOK();
      case "orange":
        return ServiceTwoDays();
      case "error":
        return ServiceError();
      case "maroon":
        return ServiceTwoHours();
      case "blue":
        return ServiceReset();
      case "loading":
        return ServiceLoading();
      default:
        return null;
    }
  };

  return <div>{ServiceState(props.type)}</div>;
}
