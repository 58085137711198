import React from "react";

export const AuthContext = React.createContext<any>({
  isLogged: false,
  userName: "",
  token: "",
  admin: false
});

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
//export default AuthContext;
