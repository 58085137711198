import React, { useContext } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import MonitoringContext from "../components/Monitoring/MonitoringContext";
import Grid from "@material-ui/core/Grid";
import MonitoringItem from "../components/Monitoring/MonitoringItem";
import CustomSeperator from "../components/Monitoring/CustomSeparator";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CircularDeterminate from "../components/Monitoring/CircularDeterminate";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import Legende from "../components/Monitoring/Legende";
import {
  statusOk,
  statusError,
  statusOrange,
  statusMaroon,
  statusBlue,
  statusLoading
} from "../services/monitoring-service";
import { EnvURL } from "../models/monitoring-models";
import MonitoringHover from "../components/Monitoring/MonitoringHover";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      color: "green"
    },
    iconOrange: {
      color: "orange"
    },
    iconMaroon: {
      color: "maroon"
    },
    iconBlue: {
      color: "blue"
    },
    popper: {
      height: "30em",
      overflowY: "auto",
      backgroundColor: "#606060"
    },
    tooltip: {},
    link: {
      textDecoration: "none"
    },
    grid: {
      display: "flex",
      justifyContent: "center",
      padding: "50px"
    }
  })
);

interface MonitoringProjectProps {
  project: string;
}

export default function MonitoringProject(props: MonitoringProjectProps) {
  const classes = useStyles();
  const monitoringContext = useContext(MonitoringContext);

  return (
    <div>
      <CustomSeperator project={props.project} />
      <br />
      <Legende />
      <br />
      <Grid className={classes.grid} container justify="center" spacing={3}>
        {monitoringContext
          .filter(projet => projet.project === props.project)
          .map(projet => {
            return (
              projet.projectEnvironmement &&
              projet.projectEnvironmement.map(
                (services: EnvURL, servicesIdx: number) => {
                  return (
                    <Grid key={servicesIdx} item xs={12} sm={2}>
                      <Link
                        to={`/${projet.project}-services/${services.environement}`}
                        key="c"
                        className={classes.link}
                      >
                        <MonitoringItem
                          key={servicesIdx}
                          title={
                            services.environement &&
                            services.environement.toUpperCase()
                          }
                          subheader={[
                            <CheckCircleIcon
                              key="check"
                              className={classes.icon}
                            />,
                            `${statusOk(services.list)}`,

                            statusError(services.list) === 0 ? null : (
                              <Tooltip
                                key="statusError"
                                title={
                                  services.list &&
                                  services.list.map(service => {
                                    if (
                                      service.fetchInfos &&
                                      "error" in service.fetchInfos
                                    )
                                      return (
                                        <MonitoringHover
                                          key={service.url}
                                          title={service.nom}
                                          subheader=""
                                        />
                                      );
                                    else {
                                      return null;
                                    }
                                  })
                                }
                              >
                                <ErrorIcon key="error" color="error" />
                              </Tooltip>
                            ),

                            statusError(services.list) === 0
                              ? null
                              : `${statusError(services.list)} `,
                            statusOrange(services.list) === 0 ? null : (
                              <Tooltip
                                key="statusOrange"
                                title={
                                  services.list &&
                                  services.list.map(service => {
                                    if (
                                      service.fetchInfos &&
                                      "currentDate" in service.fetchInfos &&
                                      parseInt(service.fetchInfos.aliveSince) >=
                                        7200000 &&
                                      parseInt(service.fetchInfos.aliveSince) <=
                                        172800000
                                    )
                                      return (
                                        <MonitoringHover
                                          key={service.url}
                                          title={service.nom}
                                          subheader=""
                                        />
                                      );
                                    else {
                                      return null;
                                    }
                                  })
                                }
                              >
                                <AccessAlarmsIcon
                                  key="iconOrange"
                                  className={classes.iconOrange}
                                />
                              </Tooltip>
                            ),
                            statusOrange(services.list) === 0
                              ? null
                              : statusOrange(services.list),
                            statusBlue(services.list) === 0 ? null : (
                              <Tooltip
                                key="statusBlue"
                                title={
                                  services.list &&
                                  services.list.map(service => {
                                    if (
                                      service.fetchInfos &&
                                      "waiting" in service.fetchInfos &&
                                      service.fetchInfos.waiting &&
                                      parseInt(service.fetchInfos.waiting) > 100
                                    )
                                      return (
                                        <MonitoringHover
                                          key={service.url}
                                          title={service.nom}
                                          subheader=""
                                        />
                                      );
                                    else {
                                      return null;
                                    }
                                  })
                                }
                              >
                                <AccessAlarmsIcon
                                  key="iconBlue"
                                  className={classes.iconBlue}
                                />
                              </Tooltip>
                            ),
                            statusBlue(services.list) === 0
                              ? null
                              : statusBlue(services.list),

                            statusMaroon(services.list) === 0 ? null : (
                              <Tooltip
                                key="statusMaroon"
                                title={
                                  services.list &&
                                  services.list.map(service => {
                                    if (
                                      service.fetchInfos &&
                                      "currentDate" in service.fetchInfos &&
                                      parseInt(service.fetchInfos.aliveSince) >
                                        0 &&
                                      parseInt(service.fetchInfos.aliveSince) <=
                                        7200000
                                    )
                                      return (
                                        <MonitoringHover
                                          key={service.url}
                                          title={service.nom}
                                          subheader=""
                                        />
                                      );
                                    else {
                                      return null;
                                    }
                                  })
                                }
                              >
                                <AccessAlarmsIcon
                                  key="iconMaroon"
                                  className={classes.iconMaroon}
                                />
                              </Tooltip>
                            ),
                            statusMaroon(services.list) === 0
                              ? null
                              : statusMaroon(services.list)
                          ]}
                          subheaderLoading={[
                            statusLoading(services.list) === 0 ? null : (
                              <Tooltip
                                key="statusLoading"
                                interactive
                                title={
                                  services.list &&
                                  services.list.map(service => {
                                    if (service.fetchInfos === undefined)
                                      return (
                                        <MonitoringHover
                                          key={service.url}
                                          title={service.nom}
                                          subheader=""
                                        />
                                      );
                                    else {
                                      return null;
                                    }
                                  })
                                }
                              >
                                <div>
                                  <CircularDeterminate key="loading" />
                                </div>
                              </Tooltip>
                            ),
                            statusLoading(services.list) === 0
                              ? null
                              : `En cours : ${statusLoading(services.list)}`
                          ]}
                          servicesList={services.list}
                          erreur={
                            services.list &&
                            services.list.some(service => {
                              if (
                                service.fetchInfos &&
                                "currentDate" in service.fetchInfos
                              ) {
                                return false;
                              } else return true;
                            })
                          }
                        />
                      </Link>
                    </Grid>
                  );
                }
              )
            );
          })}
      </Grid>
    </div>
  );
}
