import React, { useContext } from "react";
import classNames from "classnames";
import { Link as RouterLink } from "react-router-dom";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Link from "@material-ui/core/Link";
import { AuthContext } from "../Auth/AuthContext";
import Grid from "@material-ui/core/Grid";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    link: {
      textDecoration: "none",
      color: "white",

      "&:hover": {
        textDecoration: "none"
      },
      "&:visited": {
        textDecoration: "none",
        color: "white"
      }
    },
    linkMenu: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none"
      },
      "&:visited": {
        textDecoration: "none",
        color: "inherit"
      }
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: "0 8px",
      ...theme.mixins.toolbar,
      justifyContent: "flex-end"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    },
    menuAppBar: {
      display: "flex",
      justifyContent: "space-between",
      marginRight: "55px"
    },
    user: {
      color: "#b0bec5"
    }
  })
);

export default function MonitoringDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const authContext = useContext(AuthContext);
  const { login, setLogin } = useContext(AuthContext);
  const isAuth = authContext.login && authContext.login.isLogged;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar disableGutters={!open}>
          {isAuth ? (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Grid className={classes.menuAppBar} container spacing={3}>
            <Typography variant="h6" color="inherit" noWrap>
              {isAuth ? (
                <Link
                  className={classes.linkMenu}
                  component={RouterLink}
                  to="/"
                >
                  Monitoring Exyzt
                </Link>
              ) : (
                <Link
                  className={classes.linkMenu}
                  component={RouterLink}
                  to="/login"
                >
                  Monitoring Exyzt
                </Link>
              )}
            </Typography>
            <Typography variant="h6" color="inherit" noWrap>
              {authContext.login.isLogged ? (
                <Link
                  onClick={() =>
                    setLogin({ isLogged: false, userName: "", token: "" })
                  }
                  className={classes.linkMenu}
                  component={RouterLink}
                  to="/login"
                >
                  Se Déconnecter{" "}
                  <span className={classes.user}>[{login.userName}]</span>
                </Link>
              ) : (
                <Link
                  className={classes.linkMenu}
                  component={RouterLink}
                  to="/login"
                >
                  Se connecter
                </Link>
              )}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        className={classes.drawer}
        onEscapeKeyDown={handleDrawerClose}
        onBackdropClick={handleDrawerClose}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link
            className={classes.linkMenu}
            component={RouterLink}
            to="/administration"
          >
            <ListItem button key="Administration">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Administration" />
            </ListItem>
          </Link>
          <Link className={classes.linkMenu} component={RouterLink} to="/log">
            <ListItem button key="log">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Journal de logs" />
            </ListItem>
          </Link>

          <Link className={classes.linkMenu} component={RouterLink} to="/">
            <ListItem button key="Monitoring">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Monitoring" />
            </ListItem>
          </Link>
        </List>
        <Divider />
      </Drawer>
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />

        <Typography paragraph></Typography>
        <Typography paragraph></Typography>
      </main>
    </div>
  );
}
