import React, { useState, useEffect } from "react";
import { MonitoringProvider } from "./MonitoringContext";
import MonitoringRouter from "./MonitoringRouter";
import { EnvURL, TableauUrl, Project } from "../../models/monitoring-models";
import produce from "immer";
import { ServiceLog, FetchDBLog } from "../../models/monitoring-models";
import {
  GLOBAL_SERVICES_URL,
  LAST_COMMUNICATION_URL,
  LAST_LOG_ERROR_URL,
  LAST_LOG_RESET_URL,
  DB_LOG_URL,
  ALIVE_SINCE_URL
} from "../../routes";
import { dateIsoToLocale } from "../../services/monitoring-service";

interface MonitoringProps {}

const getDate = () => {
  const now = new Date();
  const UTCParis = now.setHours(now.getHours() + 1);
  const updatedDate = new Date(UTCParis);
  const finaleDate = updatedDate.toISOString().slice(0, 19);
  return finaleDate.replace("T", " ");
};

// Url qui affiche la liste des services sous forme d'un JSON formaté sur la
// base de l'interface Project
const url = GLOBAL_SERVICES_URL;

const token = localStorage.getItem("token") || "";

export default function Monitoring(props: MonitoringProps) {
  const [apiData, setApiData] = useState<Project[]>([]);
  const [monitoringInfos, setMonitoringInfos] = useState<Project[]>([]);
  const [serviceReset, setServiceReset] = useState<ServiceLog[]>([]);
  const [serviceError, setServiceError] = useState<ServiceLog[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [lastErrorLog, setLastErrorLog] = useState<FetchDBLog[]>([]);
  const [lastResetLog, setLastResetLog] = useState<FetchDBLog[]>([]);

  const slackPostMessage = (log: ServiceLog[]) => {
    const structedLog = log.map(
      (service: ServiceLog) =>
        `• *[${service.environnement}]* [<${service.url}|*${
          service.nom
        }*>] actif la dernière fois ${
          service.alive ? `le *${dateIsoToLocale(service.alive)}*` : "*jamais*"
        } \n`
    );
    fetch(process.env.REACT_APP_SLACK_URL || "http://localhost", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
      },
      body: JSON.stringify({
        // token: process.env.REACT_APP_SLACK_BOT_TOKEN,
        channel: "#monitoring",
        text: `:attention: *Rapport des ${
          log.length
        } services en erreurs* : \n ${structedLog.join("")}`
      })
    });
  };

  const updateLastCommunication = (id: string | undefined) => {
    fetch(`${LAST_COMMUNICATION_URL}/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        lastCommunication: getDate()
      })
    });
  };

  const hashCode = (s: string) => {
    for (var i = 0, h = 0; i < s.length; i++)
      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    return h;
  };

  function dynamicSort(property: string) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function(a: any, b: any) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  }

  const fetchError = () => {
    fetch(`${LAST_LOG_ERROR_URL}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    }).then(response => {
      if (response.ok) {
        response
          .json()
          .then(jsonData => {
            setLastErrorLog(jsonData);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  };

  const fetchReset = () => {
    fetch(`${LAST_LOG_RESET_URL}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    }).then(response => {
      if (response.ok) {
        response
          .json()
          .then(jsonData => {
            setLastResetLog(jsonData);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  };

  const updateLogError = (notes: ServiceLog[]) => {
    if (!loading) {
      if (
        (lastErrorLog.length > 0 &&
          lastErrorLog[0].notes.list.sort(dynamicSort("url")) &&
          notes.sort(dynamicSort("url")) &&
          hashCode(JSON.stringify(lastErrorLog[0].notes.list)) !==
            hashCode(JSON.stringify(notes))) ||
        lastErrorLog.length === 0
      ) {
        slackPostMessage(notes.sort(dynamicSort("environnement")));
        fetch(`${DB_LOG_URL}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token
          },
          body: JSON.stringify({
            user_name: "aliveSince",
            created_at: getDate(),
            data_type: "error_services",
            notes: { list: notes }
          })
        }).then(response => {
          if (response.ok) {
            setLoading(true);
            setServiceError([]);
          }
        });
      }
    }
  };

  const updateLogReset = (notes: ServiceLog[]) => {
    if (!loading) {
      if (
        (notes.length > 0 &&
          lastResetLog.length > 0 &&
          hashCode(JSON.stringify(lastResetLog[0].notes.list)) !==
            hashCode(JSON.stringify(notes))) ||
        lastResetLog.length === 0
      ) {
        fetch(`${DB_LOG_URL}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            token: token
          },
          body: JSON.stringify({
            user_name: "aliveSince",
            created_at: getDate(),
            data_type: "reset_services",
            notes: { list: notes }
          })
        }).then(response => {
          if (response.ok) {
            setLoading(true);
            setServiceReset([]);
          }
        });
      }
    }
  };

  const updateAliveSince = (id: string | undefined, aliveSince: string) => {
    fetch(`${ALIVE_SINCE_URL}/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        aliveSince: aliveSince
      })
    });
  };

  const fetchApi = () => {
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      }
    }).then(response => {
      if (response.ok) {
        response
          .json()
          .then(jsonData => {
            setApiData(jsonData);
            setMonitoringInfos(jsonData);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  };

  useEffect(() => {
    fetchApi();
    fetchError();
    fetchReset();
  }, []);

  useEffect(() => {
    if (apiData.length === 0) return;
    setLoading(true);
    Promise.allSettled(
      apiData
        .filter(project => project.projectEnvironmement !== null)
        .map((projet: Project, projectIdx: number): Promise<void>[][] => {
          return projet.projectEnvironmement
            .filter(env => env.list !== null)
            .map(
              (environnement: EnvURL, envIdx: number): Promise<void>[] =>
                environnement.list &&
                environnement.list.map(
                  (service: TableauUrl, serviceIdx: number): Promise<void> => {
                    return fetch(service.url)
                      .then(response => {
                        if (response.ok) {
                          response.json().then(jsonData => {
                            setMonitoringInfos(prevInfos => {
                              const nextInfos = produce(
                                prevInfos,
                                draftPrevInfos => {
                                  draftPrevInfos[
                                    projectIdx
                                  ].projectEnvironmement[envIdx].list[
                                    serviceIdx
                                  ].fetchInfos = jsonData;
                                }
                              );
                              return nextInfos;
                            });
                          });
                        }
                      })
                      .then(() => {
                        updateLastCommunication(service.id);
                      })

                      .catch(error => {
                        setMonitoringInfos(prevInfos => {
                          const nextInfos = produce(
                            prevInfos,
                            draftPrevInfos => {
                              draftPrevInfos[projectIdx].projectEnvironmement[
                                envIdx
                              ].list[serviceIdx].fetchInfos = {
                                error: error,
                                date: new Date().toLocaleString()
                              };
                            }
                          );
                          return nextInfos;
                        });
                      });
                  }
                )
            );
        })
        .flat()
        .flat()
        .flat()
    ).then(() => setLoading(false));
  }, [apiData]);

  // useEffect servant à mettre à jour un tableau (serviceReset) composé de l'ensemble des services ayant redémarrés
  useEffect(() => {
    setServiceReset([]);
    monitoringInfos.forEach(project => {
      if (project.projectEnvironmement === null) return;
      project.projectEnvironmement.forEach(env => {
        if (env.list === null) return;
        env.list.forEach(service => {
          if (service.fetchInfos === undefined) return;
          if (!service.fetchInfos.hasOwnProperty("aliveSince")) return;
          if (
            service.alive &&
            "aliveSince" in service.fetchInfos &&
            parseInt(service.alive) > parseInt(service.fetchInfos.aliveSince)
          ) {
            setServiceReset(prev => [
              ...prev,
              {
                nom: service.nom,
                url: service.url,
                environnement: env.environement,
                projet: project.project
              }
            ]);
          }
        });
      });
    });
  }, [monitoringInfos]);

  // useEffet servant à mettre à jour un tableau (serviceError) composé de l'ensemble des services en erreur
  useEffect(() => {
    setServiceError([]);
    monitoringInfos.forEach(project => {
      if (project.projectEnvironmement === null) return;
      project.projectEnvironmement.forEach(env => {
        if (env.list === null) return;
        env.list.forEach(service => {
          if (service.fetchInfos === undefined) return;
          if (!service.fetchInfos.hasOwnProperty("error")) return;
          setServiceError(prev => [
            ...prev,
            {
              nom: service.nom,
              url: service.url,
              alive: service.lastcommunication,
              environnement: env.environement,
              projet: project.project
            }
          ]);
        });
      });
    });
  }, [monitoringInfos]);

  // useEffect pour injecter la liste des services reset et en erreur dans la table DB_Log
  // puis la fonction updateAliveSince() vient injecter dans la bdd le dernier aliveSince
  useEffect(() => {
    monitoringInfos.forEach(project => {
      if (project.projectEnvironmement === null) return;
      project.projectEnvironmement.forEach(env => {
        if (env.list === null) return;
        env.list.forEach(service => {
          if (service.fetchInfos === undefined) return;
          if ("aliveSince" in service.fetchInfos) {
            updateAliveSince(service.id, service.fetchInfos.aliveSince);
          }
        });
      });
    });
    updateLogReset(serviceReset);
    updateLogError(serviceError);
    // eslint-disable-next-line
  }, [loading]);

  // Rechargement de la page en cours toutes les 120 sec pour rafraichissement
  // des infos
  useEffect(() => {
    setInterval(() => {
      fetchApi();
      fetchError();
      fetchReset();
    }, 180000);
  }, []);

  // Suppression du localStorage toutes les 4h afin de redemander à
  // l'utilisateur de resaisir le mot de passe.

  // useEffect(() => {
  //   setInterval(() => {
  //     localStorage.clear();
  //   }, 14400000);
  // }, []);

  return (
    <MonitoringProvider value={monitoringInfos}>
      <MonitoringRouter />
    </MonitoringProvider>
  );
}
