import {
  EnvURL,
  ErrorFetch,
  MonitoringInfo,
  TableauUrl,
  Project
} from "../models/monitoring-models";

// Fonction pour déterminer le nombre de services pour chaque status
// sur MonitoringProject
export const statusOk = (prop: TableauUrl[]) => {
  return (
    prop &&
    prop.filter(
      (service: TableauUrl) =>
        service.fetchInfos &&
        "currentDate" in service.fetchInfos &&
        (parseInt(service.fetchInfos.aliveSince) < 0 ||
          parseInt(service.fetchInfos.aliveSince) > 172800000)
    ).length
  );
};

export const statusError = (prop: TableauUrl[]) => {
  return (
    prop &&
    prop.filter(
      (service: TableauUrl) =>
        service.fetchInfos && "error" in service.fetchInfos
    ).length
  );
};

export const statusOrange = (prop: TableauUrl[]) => {
  return (
    prop &&
    prop.filter(
      (service: TableauUrl) =>
        service.fetchInfos &&
        "currentDate" in service.fetchInfos &&
        parseInt(service.fetchInfos.aliveSince) >= 7200000 &&
        parseInt(service.fetchInfos.aliveSince) <= 172800000
    ).length
  );
};

export const statusMaroon = (prop: TableauUrl[]) => {
  return (
    prop &&
    prop.filter(
      (service: TableauUrl) =>
        service.fetchInfos &&
        "currentDate" in service.fetchInfos &&
        parseInt(service.fetchInfos.aliveSince) > 0 &&
        parseInt(service.fetchInfos.aliveSince) <= 7200000
    ).length
  );
};

export const statusBlue = (prop: TableauUrl[]) => {
  return (
    prop &&
    prop.filter(
      (service: TableauUrl) =>
        service.fetchInfos &&
        "waiting" in service.fetchInfos &&
        service.fetchInfos.waiting &&
        parseInt(service.fetchInfos.waiting) > 100
    ).length
  );
};

export const statusLoading = (prop: TableauUrl[]) => {
  return (
    prop &&
    prop.filter((service: TableauUrl) => service.fetchInfos === undefined)
      .length
  );
};

function sum(previousValue: number, currentValue: number) {
  return previousValue + currentValue;
}

// Fonction pour déterminer le nombre de services pour chaque status
// pour MonitoringDashboard
export function serviceOK(project: Project) {
  return (
    project.projectEnvironmement &&
    project.projectEnvironmement
      .map(
        (services: EnvURL) =>
          services.list &&
          services.list.filter(
            service =>
              service.fetchInfos &&
              "currentDate" in service.fetchInfos &&
              (parseInt(service.fetchInfos.aliveSince) < 0 ||
                parseInt(service.fetchInfos.aliveSince) > 172800000)
          ).length
      )
      .reduce(sum)
  );
}

export function serviceOrange(project: Project) {
  return (
    project.projectEnvironmement &&
    project.projectEnvironmement
      .map(
        (services: EnvURL) =>
          services.list &&
          services.list.filter(
            service =>
              service.fetchInfos &&
              "currentDate" in service.fetchInfos &&
              parseInt(service.fetchInfos.aliveSince) <= 172800000 &&
              parseInt(service.fetchInfos.aliveSince) >= 7200000
          ).length
      )
      .reduce(sum)
  );
}

export function serviceBlue(project: Project) {
  return (
    project.projectEnvironmement &&
    project.projectEnvironmement
      .map(
        (services: EnvURL) =>
          services.list &&
          services.list.filter(
            service =>
              service.fetchInfos &&
              "waiting" in service.fetchInfos &&
              service.fetchInfos.waiting &&
              parseInt(service.fetchInfos.waiting) > 100
          ).length
      )
      .reduce(sum)
  );
}

export function serviceMaroon(project: Project) {
  return (
    project.projectEnvironmement &&
    project.projectEnvironmement
      .map(
        (services: EnvURL) =>
          services.list &&
          services.list.filter(
            service =>
              service.fetchInfos &&
              "currentDate" in service.fetchInfos &&
              parseInt(service.fetchInfos.aliveSince) > 0 &&
              parseInt(service.fetchInfos.aliveSince) <= 7200000
          ).length
      )
      .reduce(sum)
  );
}

export function serviceLoading(project: Project) {
  return (
    project.projectEnvironmement &&
    project.projectEnvironmement
      .map(
        (services: EnvURL) =>
          services.list &&
          services.list.filter(service => service.fetchInfos === undefined)
            .length
      )
      .reduce(sum)
  );
}

export function serviceError(project: Project) {
  return (
    project.projectEnvironmement &&
    project.projectEnvironmement
      .map(
        (services: EnvURL) =>
          services.list &&
          services.list.filter(
            (service: TableauUrl) =>
              service.fetchInfos && "error" in service.fetchInfos
          ).length
      )
      .reduce(sum)
  );
}

/// Methode pour déterminer la couleur des avatars pour chaque service
// sur MonitoringEnvironment

export const Color = (prop?: ErrorFetch | MonitoringInfo) => {
  if (prop && "error" in prop) {
    return true;
  }
  if (
    prop &&
    "currentDate" in prop &&
    (parseInt(prop.aliveSince) < 0 || parseInt(prop.aliveSince) > 172800000)
  ) {
    return false;
  }
  if (
    prop &&
    "currentDate" in prop &&
    parseInt(prop.aliveSince) <= 172800000 &&
    parseInt(prop.aliveSince) >= 7200000
  ) {
    return "orange";
  }
  if (prop && prop.waiting && parseInt(prop.waiting) > 100) {
    return "blue";
  }
  if (prop && "currentDate" in prop && parseInt(prop.aliveSince) <= 7200000) {
    return "maroon";
  }
};

export const dateIsoToLocale = (date: string | undefined) => {
  if (date !== undefined) {
    return new Date(date).toLocaleString();
  } else {
    return null;
  }
};
