import React from "react";

import "./App.css";
import Monitoring from "./components/Monitoring/Monitoring";
import Layout from "./components/Layout/Layout";

// const App: React.FC = () => {
//   return <Dashboard />;
// };

const App: React.FC = () => {
  return (
    <Layout>
      <Monitoring />
    </Layout>
  );
};

export default App;
