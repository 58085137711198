import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { DialogTitle } from "@material-ui/core";

interface ConfirmationDBDialogProps {
  message?: string;
  callback?: any;
  id?: string;
  error?: string;
}

export default function ConfirmationDBDialog(props: ConfirmationDBDialogProps) {
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmation = () => {
    props.id ? props.callback(props.id) : props.callback();
    setOpen(false);
  };

  return (
    <div>
      {props.error ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
            ERREUR
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmation} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* <DialogTitle id="alert-dialog-title">{props.message}</DialogTitle> */}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Annuler
            </Button>
            <Button onClick={handleConfirmation} color="primary" autoFocus>
              Valider
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
