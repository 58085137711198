import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "12px solid red",
      padding: theme.spacing(2, 4, 3)
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    title: {
      color: "red"
    }
  })
);

export default function ErrorModal(props: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classes.paper}>
      <h2 className={classes.title} id="simple-modal-title">
        Erreur de connexion
      </h2>
      <p id="simple-modal-description">{props.body}</p>
    </div>
  );

  return (
    <div>
      <Modal
        onClick={props.handleError}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
