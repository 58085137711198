import { REACT_APP_ENDPOINT } from "./secrets";

export const DELETE_ENVIRONNEMENT_URL = `${REACT_APP_ENDPOINT}/delete-environnement`;
export const GLOBAL_SERVICES_URL = `${REACT_APP_ENDPOINT}/global-services`;
export const LAST_COMMUNICATION_URL = `${REACT_APP_ENDPOINT}/last-communication`;
export const LAST_LOG_ERROR_URL = `${REACT_APP_ENDPOINT}/last-error-log`;
export const LAST_LOG_RESET_URL = `${REACT_APP_ENDPOINT}/last-reset-log`;
export const DB_LOG_URL = `${REACT_APP_ENDPOINT}/db-log`;
export const ALIVE_SINCE_URL = `${REACT_APP_ENDPOINT}/alive-since`;
export const DELETE_PROJECT_URL = `${REACT_APP_ENDPOINT}/delete-projet`;
export const DELETE_SERVICE_URL = `${REACT_APP_ENDPOINT}/delete-service`;
export const PROJETS_URL = `${REACT_APP_ENDPOINT}/projets`;
export const ENVIRONNEMENTS_URL = `${REACT_APP_ENDPOINT}/environnements`;
export const SERVICES_URL = `${REACT_APP_ENDPOINT}/services`;
export const UNIQUE_PROJET_URL = `${REACT_APP_ENDPOINT}/projet`;
export const UNIQUE_ENVIRONNEMENT_URL = `${REACT_APP_ENDPOINT}/environnement`;
export const UNIQUE_SERVICE_URL = `${REACT_APP_ENDPOINT}/service`;
export const LAST_24H_DB_LOG_URL = `${REACT_APP_ENDPOINT}/db-24h-log`;
export const LAST_MONTH_DB_LOG_URL = `${REACT_APP_ENDPOINT}/db-last-month-log`;
export const LOGIN = `${REACT_APP_ENDPOINT}/auth`;
export const REGISTER = `${REACT_APP_ENDPOINT}/register`;
export const ADMIN = `${REACT_APP_ENDPOINT}/admin-check`;
