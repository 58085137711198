import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { REGISTER } from "../../routes";
import ErrorModal from "../Utils/ErrorModal";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const token = localStorage.getItem("token") || "";

const genericRegisterError = "Utilisateur déjà existant ou données erronées !";

export default function SignIn() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [registerErrorMessage, setRegisterErrorMessage] = useState<
    string | undefined
  >(undefined);

  const history = useHistory();

  const handleErrorClose = () => {
    setRegisterErrorMessage(undefined);
  };

  const handleLogin = (
    event: React.FormEvent,
    username: string,
    userPassword: string
  ) => {
    event.preventDefault();
    fetch(`${REGISTER}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: token
      },
      body: JSON.stringify({
        userName: username,
        userPassword: userPassword,
        userEmail: userEmail
      })
    }).then(response => {
      if (response.ok) {
        console.log("User Registered");
        history.push("/login");
      }
      if (response.status === 402) {
        console.log("user exists");
      } else if (response.status === 403) {
        response
          .json()
          .then(data => {
            console.log("DATA", data);
            setRegisterErrorMessage(data.error || "Erreur inconnue.");
          })
          .catch(err => {
            console.error("DATA", err);
            setRegisterErrorMessage(genericRegisterError);
          });
      } else {
        console.log("Register error");
        setRegisterErrorMessage(genericRegisterError);
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          S'enregistrer
        </Typography>
        <form
          onSubmit={event => handleLogin(event, username, userPassword)}
          className={classes.form}
          // noValidate
        >
          <TextField
            onChange={event => setUsername(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="identifiant"
            label="identifiant"
            name="identifiant"
            autoComplete="identifiant"
            autoFocus
          />
          <TextField
            onChange={event => setUserEmail(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label="email"
            type="email"
            id="email"
            autoComplete="current-email"
          />
          <TextField
            onChange={event => setUserPassword(event.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de Passe"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            S'enregistrer
          </Button>
          <Grid container></Grid>
        </form>
      </div>
      <Box mt={8}></Box>
      {registerErrorMessage ? (
        <ErrorModal
          handleError={handleErrorClose}
          body={registerErrorMessage}
        />
      ) : null}
    </Container>
  );
}
