import React, { useContext } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import MonitoringContext from "../components/Monitoring/MonitoringContext";
import Grid from "@material-ui/core/Grid";
import CustomSeperator from "../components/Monitoring/CustomSeparator";
import Legende from "../components/Monitoring/Legende";
import { Color } from "../services/monitoring-service";
import { EnvURL } from "../models/monitoring-models";
import MonitoringDisplayService from "../components/Monitoring/MonitoringDisplayService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: "auto",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingLeft: 35,
      paddingRight: 55
    }
  })
);

interface MonitoringEnvironmentProps {
  service: string;
  project: string;
}

export default function MonitoringEnvironment(
  props: MonitoringEnvironmentProps
) {
  const classes = useStyles();
  const monitoringContext = useContext(MonitoringContext);

  // Fonction permettant de convertir les données du aliveSince de milliseconds
  // en jours heure minute secondes

  const convertMS = (milliseconds: number) => {
    let day, hour, minute, seconds;
    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;

    if (day === 0) {
      return `${hour} heures, ${minute} minutes`;
    }
    if (hour === 0) {
      return `${day} jours, ${minute} minutes`;
    }
    if (day === 0 && hour === 0) {
      return `${minute} minutes`;
    } else {
      return `${day} jours, ${hour} heures, ${minute} minutes`;
    }
  };

  const dateIsoToLocale = (date: string | undefined) => {
    if (date !== undefined) {
      return new Date(date).toLocaleString();
    } else {
      return null;
    }
  };

  return (
    <div>
      <CustomSeperator project={props.project} service={props.service} />
      <br />
      <Legende />
      <br />
      <Grid className={classes.root} container justify="center" spacing={3}>
        {monitoringContext
          .filter(project => project.project === props.project)
          .map(project =>
            project.projectEnvironmement
              .filter(services => services.environement === props.service)
              .map((services: EnvURL) => {
                return (
                  services.list &&
                  services.list
                    // .sort(service =>
                    //   tri(service))
                    .map(service => {
                      return (
                        <Grid key={service.url} item sm={6} lg={2}>
                          <a
                            href={service.url}
                            key={service.url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <MonitoringDisplayService
                              key={service.url}
                              title={service.nom}
                              subheader={
                                service.fetchInfos &&
                                "error" in service.fetchInfos
                                  ? service.fetchInfos.error.toString() +
                                    ` / Service injoignable depuis le ` +
                                    dateIsoToLocale(service.lastcommunication)
                                  : service.fetchInfos &&
                                    `Actif depuis ` +
                                      convertMS(
                                        parseInt(service.fetchInfos.aliveSince)
                                      )
                              }
                              servicesList={services.list}
                              erreur={Color(service.fetchInfos)}
                            />
                          </a>
                        </Grid>
                      );
                    })
                );
              })
          )}
      </Grid>
    </div>
  );
}
