import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbs: {
      justifyContent: "center",
      flexWrap: "wrap"
    },
    paper: {
      padding: theme.spacing(1, 2),
      backgroundColor: "#fafafa"
    }
  })
);

interface CustomSeparatorProps {
  project?: string;
  service?: string;
}

export default function CustomSeparator(props: CustomSeparatorProps) {
  const classes = useStyles();

  return (
    <div className={classes.breadcrumbs}>
      <Paper elevation={0} className={classes.paper}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link to="/" color="inherit" component={RouterLink}>
            Monitoring
          </Link>
          <Link to={`/${props.project}`} color="inherit" component={RouterLink}>
            <Typography color="textPrimary">{props.project}</Typography>
          </Link>
          <Typography color="textPrimary">{props.service}</Typography>
        </Breadcrumbs>
      </Paper>
    </div>
  );
}
